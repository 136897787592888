import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ProduitCard = ({ data }) => {
  return (
    <div key={data.id} className="col-lg-4 col-md-6 mb-3" data-aos="fade-up">
      <Link to={`/produits/${data.slug}`}>
        <div className="card card-bordered card-transition shadow">
          <div className="mb-4">
            <GatsbyImage
              image={getImage(data.frontmatter.card_image)}
              className="card-img-top"
              alt={data.frontmatter.card_image_alt}
            />
          </div>
          <div className="card-body">
            <p className="h4 text-dark">{data.frontmatter.titre}</p>
            <p className="h5 text-muted">{data.frontmatter.marque}</p>
            <p className="text-muted">{data.frontmatter.categorie}</p>
          </div>
        </div>
      </Link>
     </div>
  )
}

export default ProduitCard